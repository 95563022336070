//BRAND
export const name = 'aiversity';
export const company = 'ADTC.NG';
export const websiteURL = 'https://aiversity.adtc.ng';
export const serverURL = 'https://aiversity.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/aiversity-482d0.appspot.com/o/Aiversity%20logo.png?alt=media&token=f30f7ffd-3101-4e8b-a62a-827b68de380c';
export const razorpayEnabled = false;
export const paypalEnabled = false;
export const stripeEnabled = false;
export const paystackEnabled = true;
export const flutterwaveEnabled = true;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 1550;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 11999;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "This is really exciting. My husband and I have been thinking of ways to engage our kids productively during the holidays for a while. aiversity really fulfills our need and concerns; we just decide on what subject and topics we want the kids to learn, and the ai coach creates them. It is really amazing; the kids love it. I recommend this to all parents and guardians, it is really affordable.";
export const from = "Mrs. M. Adeboye";
export const profession = 'Banker';
export const photoURL = '';

//PAYPAL
export const paypalPlanIdOne = "P-1EM732768S920784HMWKW3OA";
export const paypalPlanIdTwo = "P-8T744865W27080359MWOCE5Q";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1OTo7CSDXmLtVnVeaHIHxqCj";
export const stripePlanIdTwo = "price_1OTo7eSDXmLtVnVeBbn82U5B";

//PAYSTACK
export const paystackPlanIdOne = "PLN_suxx7i6fdgblmrj";
export const paystackPlanIdTwo = "PLN_zvk9jhckfmv11fg";
export const amountInZarOne = '1250';
export const amountInZarTwo = '11999';

//FLUTTERWAVE
export const flutterwavePlanIdOne = "67960";
export const flutterwavePlanIdTwo = "67961";
export const flutterwavePublicKey = "FLWPUBK_TEST-6ee1faf6460ea587f510a024ac4c2b23-X";

//SOCIAL SIGNIN
export const googleClientId = "150644331076-flt197jvuqg9ohlf6q9rsjk3e3qomjd2.apps.googleusercontent.com";
export const facebookClientId = "8065327730213158";